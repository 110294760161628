<template>
    <div>   
        <div class="row">
			<!-- Produk List -->
			<div class="col-md-12 mb-4">
				<div class="card shadow border-0 round">
					<div class="card-body p-4">
						<div class="d-md-flex justify-content-between align-items-center">
							<!-- Mencetak deskripsi halaman berdasarkan halaman apa yang sedang dibuka -->
							<p class="my-0 text-muted mx-2 text-center" v-if="isProduct">Menampilkan <span class="text-dark">{{ total_product }} produk</span> untuk penelusuran produk <span class="text-dark">'{{ keyword | capitalize }}'</span>.</p>
							<p class="my-0 text-muted mx-2 text-center" v-else-if="isPromo">Menampilkan <span class="text-dark">{{ total_product }} produk</span> untuk penelusuran promo <span class="text-dark">'{{ this.keyword.split('-').join(' ') | capitalize }}'</span>.</p>
							<p class="my-0 text-muted mx-2 text-center" v-else-if="isCategory">Menampilkan <span class="text-dark">{{ total_product }} produk</span> untuk penelusuran kategori <span class="text-dark">'{{ keyword.substring(this.keyword.indexOf('-') + 1) | capitalize }}'</span>.</p>
							<p class="my-0 text-muted mx-2 text-center" v-else-if="isUMKM">Menampilkan <span class="text-dark">{{ total_product }} produk</span> untuk penelusuran <span class="text-dark">Produk UMKM</span>.</p>
							
							<!-- <div class="d-flex justify-content-start justify-content-center m-2">
								<p class="mt-2 mb-0 mr-2 text-muted">Urutkan: </p>
								<select class="custom-select" style="width: 170px;" v-model="selectedSort">
								  	<option value="popularity" selected>Terpopuler</option>
								  	<option value="price_asc">Harga Terendah</option>
								  	<option value="price_desc">Harga Tertinggi</option>
								  	<option value="review">Ulasan</option>
								</select>
							</div> -->
						</div>
						<hr />
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div v-else>
							<!-- Jika terdapat produk -->
							<div v-if="total_product > 0">
								<!-- Maka tampilkan produk -->
								<div class="row">
									<div class="col-lg-3 col-md-6 col-6 p-2" v-for="(product, index) in products" :key="index">
										<div class="card product round overflow-hidden">
											<!-- Route to detail produk jika image diklik -->
											<router-link :to="'/product-detail/'+product.prod_id+'-'+product.prod_name.toLowerCase()" class="text-decoration-none">
												<div class="d-flex align-items-center" style="height: 208px; overflow: hidden;">
													<img v-lazy="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top" :alt="product.prod_image" />
												</div>
											</router-link>
											<!-- Tombol Add Favorite -->
											<div class="card-body border-top p-3 product-card text-muted" style="height:170px;">
												<button class="btn p-0 floating-button overflow-hidden" @click="add_favorite(product.prod_id)">
													<button class="btn btn-outline-danger rounded-circle"><i class="fa fa-heart"></i></button>
												</button>
												<!-- Route to detail produk jika area di bawah image diklik -->
												<router-link :to="'/product-detail/'+product.prod_id+'-'+product.prod_name.toLowerCase()" class="text-decoration-none">
													<!-- Nama Produk -->
													<p class="mb-0 text-dark" style="height:40px; overflow: hidden;">{{ product.prod_name | str_limit(25) | capitalize }}</p>
													<!-- Summary/detail sekilas produk -->
													<p class="text-muted mb-0 text-truncate">{{ product.prod_summary | str_limit(15) }}</p>
													<!-- Menentukan tipe discount jika ada discount lalu menampilkannya-->
													<!-- <div v-if="product.prod_discount">
														<h6 class="text-success my-1"><strong>{{ discountedPrice(product.prod_price, product.prod_discount) | formatCurrency('IDR') }}</strong></h6>
														<p class="mb-2">
															<small class="bg-danger text-white round px-2 mr-2">{{ product.prod_discount }}%</small> 
															<strike class="text-muted">{{ product.prod_price | formatCurrency('IDR') }}</strike>
														</p>
													</div>
													<div v-else-if="product.pp_promo_discount">
														<h6 class="text-success my-1"><strong>{{ discountedPrice(product.prod_price, product.pp_promo_discount) | formatCurrency('IDR') }}</strong></h6>
														<p class="mb-2">
															<small class="bg-danger text-white round px-2 mr-2">{{ product.pp_promo_discount }}%</small> 
															<strike class="text-muted">{{ product.prod_price | formatCurrency('IDR') }}</strike>
														</p>
													</div>
													<div v-else>
														<h6 class="text-success my-3"><strong>{{ product.prod_price | formatCurrency('IDR') }}</strong></h6>
													</div> -->
													<h6 class="text-success my-3"><strong>-</strong></h6>
													<!-- Menampilkan rating product -->
													<div class="d-inline-flex justify-content-center align-items-center" v-for="index2 in 5" :key="index2">
														<i class="fa fa-star text-warning" v-if="index2 <= product.prod_rating"></i>
														<div class="fa-stack" style="height: 16px; line-height: 22px; width: 15px;" v-else-if="index2 - 0.5 <= product.prod_rating">
															<i class="fa-stack-1x fa fa-star text-secondary"></i>						
															<i class="fa-stack-1x fa fa-star-half text-warning"></i>						
														</div>
														<i class="fa fa-star text-secondary" v-else></i>
													</div>
													<span class="text-secondary pl-2" v-if="product.prod_rating">({{ product.prod_rating }})</span>
												</router-link>
											</div>
											<!-- Tombol masukkan keranjang -->
											<div class="card-footer d-inline-flex w-100 px-1 py-2 d-flex justify-content-center">
												<!-- <button class="btn btn-outline-success w-75 m-1 px-1" @click="plusKeranjang(product.prod_id)"><i class="fa fa-cart-plus"></i> Keranjang</button> -->
												<button class="btn btn-outline-success w-75 m-1 px-1" disabled><i class="fa fa-cart-plus"></i> Keranjang	</button>
												<button class="btn btn-outline-info m-1" @click="add_compare(product.prod_id)"><i class="fa fa-sliders-h"></i></button>
											</div>
										</div>
									</div>
								</div>
								<!-- Pengaturan page bagian product -->
								<div class="row">
									<div class="col-md-12 w-100 text-center">
										<hr />
										<b-pagination v-model="current_page" :per-page="per_page" @change="getProducts" :total-rows="total_product" first-number last-number align="center" size="md" v-if="isProduct"></b-pagination>
										<b-pagination v-model="current_page" :per-page="per_page" @change="get_productsPromo" :total-rows="total_product" first-number last-number align="center" size="md" v-else-if="isPromo"></b-pagination>
										<b-pagination v-model="current_page" :per-page="per_page" @change="get_productsCategory" :total-rows="total_product" first-number last-number align="center" size="md" v-else-if="isCategory"></b-pagination>
										<b-pagination v-model="current_page" :per-page="per_page" @change="get_productsUMKM" :total-rows="total_product" first-number last-number align="center" size="md" v-else-if="isUMKM"></b-pagination>
									</div>
								</div>
							</div>
							<!-- Jika tidak terdapat produk -->
							<div v-else>
								<!-- Maka cetak kalimat berikut -->
								<div class="text-center my-5">
									<h4 class="text-info mb-1">Oops!</h4>
									<p class="text-muted">Produk yang dicari tidak tersedia!</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ProductViewList',
  	data () {
 		return {
			// selectedSort: 'popularity',
			products: [],
			current_page: this.$session.get('lastPageProductList') || 1,
			per_page: 0,
			total_product: 0,
			isRefresh: false,
			isProduct: false,
			isPromo: false,
			isCategory: false,
			isUMKM: false,
 		}
	},
	methods: {
    	getProducts(current_page) {
			this.isRefresh = true
			this.total_product = 0
			if(this.keyword === 'Semua Produk') {
				window.axios.get('/catalogue/search?page='+ current_page)
 				.then((response) => {
					this.isRefresh = false
					this.per_page = response.data.data.per_page
					this.total_product = response.data.data.total
					this.products = response.data.data.data

					let position = $(document).scrollTop()
					$('html, body').stop().animate({
						scrollTop: 5
					}, 1000, 'easeInOutExpo')
 				})
 				.catch((error) => {
 					console.log(error)
				})
			}
			else {
				window.axios.get('/catalogue/search?q='+ this.keyword +'&page='+ current_page)
 				.then((response) => {
					this.isRefresh = false
					this.per_page = response.data.data.per_page
					this.total_product = response.data.data.total
					this.products = response.data.data.data

					let position = $(document).scrollTop()
					$('html, body').stop().animate({
						scrollTop: 5
					}, 1000, 'easeInOutExpo')
 				})
 				.catch((error) => {
 					console.log(error)
				})
			}
			this.current_page = current_page
		},
		get_productsCategory(current_page) {
			let slug = this.keyword.substr(0, this.keyword.indexOf('-'))
			
			this.isRefresh = true
			this.total_product = 0
			window.axios.get('/catalogue/category?category_id='+ slug + '&page=' + current_page)
 			.then((response) => {
				this.isRefresh = false
				this.total_product = response.data.data.total
				this.per_page = response.data.data.per_page
				this.current_page = current_page
				this.products = response.data.data.data

				let position = $(document).scrollTop()
				$('html, body').stop().animate({
					scrollTop: 5
				}, 1000, 'easeInOutExpo')
 			})
 			.catch((error) => {
 				console.log(error)
			})
		},
		get_productsUMKM(current_page) {
			this.isRefresh = true
			this.total_product = 0
			window.axios.get('/catalogue/umkm?page=' + current_page)
 			.then((response) => {
				this.isRefresh = false
				this.total_product = response.data.data.total
				this.per_page = response.data.data.per_page
				this.current_page = current_page
				this.products = response.data.data.data

				let position = $(document).scrollTop()
				$('html, body').stop().animate({
					scrollTop: 5
				}, 1000, 'easeInOutExpo')
 			})
 			.catch((error) => {
 				console.log(error)
			})
		},
		get_productsPromo(current_page) {
			this.isRefresh = true
			this.total_product = 0
			window.axios.get('/promo/'+ this.keyword + '?page=' + current_page)
 			.then((response) => {
				this.total_product = response.data.data.total
				this.per_page = response.data.data.per_page
				this.current_page = current_page
				this.products = []
				
				// GET DETAIL PRODUK
				for(let i=0; i<response.data.data.data.length; i++) {
					this.get_detail_product(response.data.data.data[i].prod_id, response.data.data.data[i])
				}
				
				this.isRefresh = false

				let position = $(document).scrollTop()
				$('html, body').stop().animate({
					scrollTop: 5
				}, 1000, 'easeInOutExpo')
 			})
 			.catch((error) => {
 				console.log(error)
			})
		},
		get_detail_product(id, data) {
			let temp = []
			window.axios.get('/catalogue/detail/'+ id)
 			.then((response) => {
				temp = Object.assign({}, data, response.data.data)
				this.products.push(temp)
 			})
 			.catch((error) => {
 				console.log(error)
			})
		},
		plusKeranjang(prod_id) {

			if(this.isLoggedIn) {
				let formData = new FormData();
				formData.append('prod_id', prod_id);
				formData.append('prod_qty','1');

				window.axios.post('/cart', formData, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
							"token": this.$store.getters.token_member
                        }
                    }
                )
 				.then((response) => {
					window.NavbarDirtyAccess.get_productKeranjang()
					
					this.$swal({
						toast: true,
						timer: 4000,
						position: 'top-end',
						showConfirmButton: false,
						icon: 'success',
						html: '<p class="mb-0 mx-3">Produk berhasil ditambahkan ke dalam keranjang.</p>',
						customClass: {
							container: 'mt-5 pt-5',
							popup: 'border border-success mt-5 px-4',
						}
					})
 				})
 				.catch((error) => {
 					console.log(error)
				})
			}
			else {
				this.$session.set('product_keranjang', [{'prod_id': prod_id, 'prod_qty': 1}])
				this.$router.push('/login')
			}
		},
		add_favorite(id) {
			if(this.isLoggedIn) {
				let formData = new FormData();
				formData.append('prod_id', id);

				window.axios.post('/wishlist', formData, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
							"token": this.$store.getters.token_member
                        }
                    }
                )
 				.then((response) => {
					window.NavbarDirtyAccess.get_productFavorite()
					
					this.$swal({
						toast: true,
						timer: 4000,
						position: 'top-end',
						showConfirmButton: false,
						icon: 'success',
						html: '<p class="mb-0 mx-3">Produk berhasil difavoritkan.</p>',
						customClass: {
							container: 'mt-5 pt-5',
							popup: 'border border-success mt-5 px-4',
						}
					})
 				})
 				.catch((error) => {
					if(!error.response.data.success) {
						this.$swal({
							title: 'Oops!', 
							html: '<p>Produk sudah pernah ditambahkan ke dalam Wishlist sebelumnya.</p>',
							icon: 'error',
							timer: 7000,
							showConfirmButton: false,
						});
					}
				})
			}
			else {
				this.$router.push('/login')
			}
		},
		add_compare(id) {
			window.axios.get('/catalogue/detail/'+ id)
 			.then((response) => {
				
				let product = JSON.stringify(response.data.data)
				let id_productSaved = ''
				let flag = 1
				
				if(!localStorage.getItem('prod_compare_0')) {
					
					// CEK APAKAH PRODUK SUDAH ADA DI LOCALSTORAGE (MENGHINDARI DUPLIKASI DATA)
					if(localStorage.getItem('prod_compare_1')) {
						id_productSaved = JSON.parse(localStorage.getItem('prod_compare_1')).prod_id
						
						if(id_productSaved == id) {
							flag = 0
						}
					}
					if(localStorage.getItem('prod_compare_2')) {
						id_productSaved = JSON.parse(localStorage.getItem('prod_compare_2')).prod_id
						
						if(id_productSaved == id) {
							flag = 0
						}
					}
					
					if(flag == 1) {
						localStorage.setItem('prod_compare_0', product)
					}
				}
				else {
					if(!localStorage.getItem('prod_compare_1')) {
						// CEK APAKAH PRODUK SUDAH ADA DI LOCALSTORAGE (MENGHINDARI DUPLIKASI DATA)
						if(localStorage.getItem('prod_compare_0')) {
							id_productSaved = JSON.parse(localStorage.getItem('prod_compare_0')).prod_id
							
							if(id_productSaved == id) {
								flag = 0
							}
						}
						if(localStorage.getItem('prod_compare_2')) {
							id_productSaved = JSON.parse(localStorage.getItem('prod_compare_2')).prod_id
							
							if(id_productSaved == id) {
								flag = 0
							}
						}
						
						if(flag == 1) {
							localStorage.setItem('prod_compare_1', product)
						}
					}
					else {
						// CEK APAKAH PRODUK SUDAH ADA DI LOCALSTORAGE (MENGHINDARI DUPLIKASI DATA)
						if(localStorage.getItem('prod_compare_0')) {
							id_productSaved = JSON.parse(localStorage.getItem('prod_compare_0')).prod_id
							
							if(id_productSaved == id) {
								flag = 0
							}
						}
						if(localStorage.getItem('prod_compare_1')) {
							id_productSaved = JSON.parse(localStorage.getItem('prod_compare_1')).prod_id
							
							if(id_productSaved == id) {
								flag = 0
							}
						}
						
						if(flag == 1) {
							localStorage.setItem('prod_compare_2', product)
						}
					}
				}
				
				if(flag == 0) {
					this.$swal({
						title: 'Oops!', 
						html: '<p>Produk sudah pernah ditambahkan sebelumnya.</p>',
						icon: 'error',
						timer: 7000,
						showConfirmButton: false,
					});
				}
 			})
 			.catch((error) => {
				if(!error.response.data.success) {
					this.$swal({
						title: 'Oops!', 
						html: '<p>'+ error.response.data.message +'</p>',
						icon: 'error',
						timer: 7000,
						showConfirmButton: false,
					});
				}
			})
			.finally(() => {
				this.$router.push('/product/compare')
			})
		},
	},
    computed: {
      	isLoggedIn : function(){
      	  	return this.$store.getters.isLoggedIn
      	},
		isLoading() {
			return this.$store.getters.isLoading
      	},
        keyword: function () {
			return this.$route.query.q
		},

		// sortedProducts: function() {
		// 	if(this.selectedSort == 'popularity') {
		// 		return this.products.sort((a, b) => a.prod_views - b.prod_views).reverse()
		// 	}
		// 	else if(this.selectedSort == 'price_asc') {
		// 		return this.products.sort((a, b) => a.prod_price - b.prod_price)
		// 	}
		// 	else if(this.selectedSort == 'price_desc') {
		// 		return this.products.sort((a, b) => a.prod_price - b.prod_price).reverse()
		// 	}

		// 	return this.products.sort((a, b) => a.prod_rate - b.prod_rate).reverse()
		// }
	},
	created() {
		if(this.keyword != this.$session.get('lastQueryProductList')) {
			this.current_page = 1
		}
		
		if(this.$route.query.type == 'promo') {
			this.isPromo= true
			this.isCategory= false
			this.isUMKM= false
			this.isProduct= false
			this.get_productsPromo(this.current_page)
		}
		else if(this.$route.query.type == 'category'){
			this.isPromo= false
			this.isCategory= true
			this.isUMKM= false
			this.isProduct= false
			this.get_productsCategory(this.current_page)
		}
		else if(this.$route.query.type == 'umkm'){
			this.isPromo= false
			this.isCategory= false
			this.isUMKM= true
			this.isProduct= false
			this.get_productsUMKM(this.current_page)
		}
		else {
			this.isPromo= false
			this.isCategory= false
			this.isUMKM= false
			this.isProduct= true
			this.getProducts(this.current_page)
		}
		
		window.ProductViewList = this
	},
	watch: {
    	'$route.query.q': function () {
		
			if(this.keyword != this.$session.get('lastQueryProductList')) {
				this.current_page = 1
			}
			
			if(this.$route.query.type == 'promo') {
				window.ProductList.isPromo= true
				window.ProductList.isCategory= false
				window.ProductList.isUMKM= false
				window.ProductList.isProduct= false
				
				this.isPromo= true
				this.isCategory= false
				this.isUMKM= false
				this.isProduct= false
				this.get_productsPromo(this.current_page)
			}
			else if(this.$route.query.type == 'category'){
				window.ProductList.isPromo= false
				window.ProductList.isCategory= true
				window.ProductList.isUMKM= false
				window.ProductList.isProduct= false
				
				this.isPromo= false
				this.isCategory= true
				this.isUMKM= false
				this.isProduct= false
				this.get_productsCategory(this.current_page)
			}
			else if(this.$route.query.type == 'umkm'){		
				window.ProductList.isPromo= false
				window.ProductList.isCategory= false
				window.ProductList.isUMKM= true
				window.ProductList.isProduct= false
				
				this.isPromo= false
				this.isCategory= false
				this.isUMKM= true
				this.isProduct= false
				this.get_productsUMKM(this.current_page)
			}
			else {
				window.ProductList.isPromo= false
				window.ProductList.isCategory= false
				window.ProductList.isUMKM= false
				window.ProductList.isProduct= true
				
				this.isPromo= false
				this.isCategory= false
				this.isUMKM= false
				this.isProduct= true
				this.getProducts(this.current_page)
			}
		}
  	},
}
</script>
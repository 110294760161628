<template>
    <div>   
        <div class="row">
			<!-- Kategori List -->
			<div class="col-md-12 mb-4">
				<div class="card shadow border-0 round">
					<div class="title text-white "><h6 class="text-center mb-0"><i class="fa fa-align-left fa-lg"></i> &nbsp; &nbsp;<strong>Daftar Kategori</strong></h6></div>
					<ul id="category-tabs" v-for="(category, index) in categories" :key=index>
						<li>
							<router-link :to="'/product-list?type=category&q='+ category.cat_id +'-'+ category.cat_name.toLowerCase()">
								<h6 class="mb-0">
									<span :class="'fa '+ category.cat_icon +' mr-2'"></span> &nbsp; 
									{{ category.cat_name | capitalize }}
								</h6>
							</router-link>
							<a><i class="fa fa-chevron-down"></i></a>
							<ul>
								<template v-for="sub_cat in category.sub_category">
									<router-link :to="'/product-list?type=category&q='+ sub_cat.cat_id +'-'+ sub_cat.cat_name.toLowerCase()" :key=sub_cat.cat_id><li>{{ sub_cat.cat_name | capitalize }}</li></router-link>
								</template>
							</ul>
						</li>
					</ul>
				</div>
			</div>
			
			<!-- Manufacturer List -->
			
			<!-- <div class="col-md-12 mb-4">
				<div class="card shadow border-0 round">
					<div class="title text-white"><h6 class="text-center mb-0"><i class="fa fa-align-left fa-lg"></i> &nbsp; &nbsp;<strong>Daftar Brand</strong></h6></div>
					<div class="container my-4">
						<div class="input-group mb-3">
							<div class="input-group-prepend">
								<div class="input-group-text round"><i class="fa fa-search"></i></div>
							</div>
							<input type="text" class="form-control round" v-model="keywordManufacture" placeholder="Filter Nama Brand">
						</div>
						<div class="text-center my-3" v-if="isLoading">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div v-else>
							<ul class="list-group list-group-flush manufacturer-list" v-if="filteredManufacture.length > 0">
								<li class="list-group-item py-2" v-for="manufacture in filteredManufacture" :key="manufacture.brand_id">
									<router-link :to="'/product-list?type=product&q='+ manufacture.brand_name" class="text-decoration-none product-category">
										{{ manufacture.brand_name  }} ({{ manufacture.total_product }} Produk)
									</router-link>
								</li>
							</ul>
							<div class="text-center w-100 p-3" v-else>
								<h5 class="text-info">Oops!</h5>
								<p class="text-muted">Brand tidak ditemukan</p>
							</div>
						</div>
					</div>
				</div>
			</div> -->
			
			<!-- Filter -->
			<!-- <div class="col-md-12 mb-4">
				<div class="card shadow border-0 round">
					<div class="title text-white"><h6 class="text-center mb-0"><i class="fa fa-align-left fa-lg"></i> &nbsp; &nbsp;<strong>Filter</strong></h6></div>
					<ul id="category-tabs">
						<li>
							<a><h6 class="mb-0"><span class="fa fa-money-bill-wave"></span> &nbsp;Harga<i class="fa fa-chevron-down"></i></h6></a>
							<ul>
								<li>
									<label class="text-muted mb-1">Harga Minimum</label>
									<div class="input-group mb-2 mr-sm-2">
										<div class="input-group-prepend">
											<div class="input-group-text round"><strong>Rp</strong></div>
										</div>
										<input type="text" v-model.number="hargaMin" class="form-control round" placeholder="Minimum">
									</div>
									<label class="text-muted mb-1">Harga Maksimum</label>
									<div class="input-group mb-2 mr-sm-2">
										<div class="input-group-prepend">
											<div class="input-group-text round"><strong>Rp</strong></div>
										</div>
										<input type="text" v-model.number="hargaMax" class="form-control round" placeholder="Maksimum">
									</div>
								</li>
							</ul>
						</li>
					</ul>
					<ul id="category-tabs">
						<li>
							<a><h6 class="mb-0"><span class="fa fa-star"></span> &nbsp;Ulasan<i class="fa fa-chevron-down"></i></h6></a>
							<ul>
								<li>
									<div class="form-check d-flex align-items-center">
										<input class="form-check-input mt-0" type="checkbox" v-model="fourStar" value="4" id="checkbox-4star" style="transform: scale(1.2);">
										<label class="form-check-label" for="checkbox-4star">
											&nbsp; 
											<i class="fa fa-star text-warning fa-md"></i>
											Bintang 4
										</label>
									</div>
									<div class="form-check d-flex align-items-center">
										<input class="form-check-input mt-0" type="checkbox" v-model="fiveStar" value="5" id="checkbox-5star" style="transform: scale(1.2);">
										<label class="form-check-label" for="checkbox-5star">
											&nbsp; 
											<i class="fa fa-star text-warning fa-md"></i>
											Bintang 5
										</label>
									</div>
								</li>
							</ul>
						</li>
					</ul>
					<ul id="category-tabs">
						<li>
							<a><h6 class="mb-0"><span class="fa fa-tags"></span> &nbsp;Promo<i class="fa fa-chevron-down"></i></h6></a>
							<ul>
								<li>
									<div class="form-check d-flex align-items-center">
										<input class="form-check-input mt-0" type="checkbox"  v-model="discout"  value="Discout" id="checkbox-diskon" style="transform: scale(1.2);">
										<label class="form-check-label" for="checkbox-diskon">
											&nbsp; 
											<i class="fa fa-tag text-success fa-md"></i>
											Diskon
										</label>
									</div>
								</li>
							</ul>
						</li>
					</ul>

					<div class="text-center my-3">
						<button class="btn btn-primary" v-on:click="filterProduct()">Terapkan <i class="fa fa-paper-plane"></i></button>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
import Vue from 'vue'

export default {
	name: 'FilterProduct',
  	data () {
 		return {
			categories: [],
			// manufactures: [],
			//keywordManufacture: '',
 		}
	},
	computed: {
		isLoading() {
			return this.$store.getters.isLoading
      	},
		// filteredManufacture: function () {
		//	 return this.manufactures.filter((temp) => {
       	//	 	 return this.keywordManufacture.toLowerCase().split(' ').every(v => temp.brand_name.toLowerCase().includes(v))
      	//	 })
		// }
 	},
	methods: {
		get_iconCategory(cat_name) {
			if (cat_name == "alat listrik") {
				return 'fa-bolt'
			}
			if (cat_name == "alat listrik") {
				return 'fa-bolt'
			}
			else if(cat_name == "alat bantu") {
				return 'fa-gavel'
			}
			else if(cat_name == "alat teknik") {
				return 'fa-allergies'
			}
			else if(cat_name == "jasa") {
				return 'fa-user-cog'
			}
			else if(cat_name == "makanan & minuman") {
				return 'fa-cookie-bite'
			}
			else if(cat_name == "health care") {
				return 'fa-briefcase-medical'
			}
			else if(cat_name == "kebutuhan rumah") {
				return 'fa-home'
			}
			else if(cat_name == "makanan ringan") {
				return 'fa-ice-cream'
			}
			else if (cat_name == "bahan masakan") {
				return 'fa-cookie-bite'
			}
			else if (cat_name == "safety & accesories") {
				return 'fa-gavel'
			}
			else if (cat_name == "paket sembako dm") {
				return 'fa-cube'
			}
			else if (cat_name == "konsumable") {
				return 'fa-blender'
			}
			else if (cat_name == "kebutuhan kantor") {
				return 'fa-landmark'
			}
			else if (cat_name == "makanan instan & siap saji") {
				return 'fa-cookie'
			}
			else if (cat_name == "accesories listrik") {
				return 'fa-charging-station'
			}
			else if (cat_name == "kabel") {
				return 'fa-project-diagram'
			}
			else if (cat_name == "komponen instalasi") {
				return 'fa-puzzle-piece'
			}
			else if (cat_name == "komponen panel") {
				return 'fa-solar-panel'
			}
			else if (cat_name == "komponen teknik dengan listrik") {
				return 'fa-bolt'
			}
			else if (cat_name == "komponen teknik dengan mesin") {
				return 'fa-hdd'
			}
			else if (cat_name == "komponen teknik dengan tangan") {
				return 'fa-allergies'
			}
			else if (cat_name == "pneumatik") {
				return 'fa-microchip'
			}
			
			return 'fa-gifts'
		},
	},
    created () {
 		// window.axios.get('/home/brand')
 		// .then((response) => {
		// 	this.manufactures = response.data.data
 		// })
 		// .catch((error) => {
 		// 	console.log(error)
		// })
		
		// KATEGORI PRODUK
		window.axios.get('/category')
 		.then((response) => {
			this.categories = response.data.data
			
			let temp = []
			let icon = ''
			let count = 0
			
			for(let i=0; i<response.data.data.length; i++) {
				if(count < 8) {
					icon = this.get_iconCategory(response.data.data[i].cat_name.toLowerCase())
					
					// SISIPKAN ICON PADA ARRAY
					this.categories[i]['cat_icon'] = icon
				}
				count += 1
			}
			Vue.nextTick(function() {
				$('#category-tabs li a').on('click', function () {
					$(this).toggleClass('text-primary')
					$(this).next('ul').slideToggle('300')
					$(this).find('i').toggleClass('fa-chevron-up fa-chevron-down')
				});
			})
 		})
 		.catch((error) => {
    		console.log(error)
		})
    },
}

</script>
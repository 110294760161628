<template>
  	<div>
        <main class="container-fluid">
            <div class="row mx-md-4">
				
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<li class="breadcrumb-item text-secondary">Penelusuran</li>
									<li class="breadcrumb-item active" aria-current="page" v-if="isPromo">Promo Produk</li>
									<li class="breadcrumb-item active" aria-current="page" v-else-if="isCategory">Kategori Produk</li>
									<li class="breadcrumb-item active" aria-current="page" v-else-if="isProduct">{{ keyword | capitalize }}</li>
									<li class="breadcrumb-item active" aria-current="page" v-else-if="isUMKM">Produk UMKM</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>

				<div class="col-md-3 mb-4">
					<FilterProduct />
				</div>
				
				<div class="col-md-9 mb-4">
					<ProductViewList />
				</div>
            </div>
        </main>
    </div>
</template>

<script>
import FilterProduct from '@/components/FilterProduct.vue'
import ProductViewList from '@/components/ProductViewList.vue'

export default {
	components: {
  		FilterProduct, ProductViewList
	},
	data () {
 		return {
			isProduct: false,
			isPromo: false,
			isCategory: false,
			isUMKM: false,
 		}
	},
    computed: {
        keyword: function () {
			return this.$route.query.q
        },
	},
	updated() {
		let position = $(document).scrollTop()
		// $('html, body').stop().animate({
		// 	scrollTop: 1
		// }, 1000, 'easeInOutExpo')
	},
	methods: {
		createSessions_onRouteLeave() {
			this.$session.set('lastPageProductList', window.ProductViewList.current_page)
			this.$session.set('lastQueryProductList', window.ProductViewList.keyword)
		}
	},
	created() {
		this.$session.start()
		let position = $(document).scrollTop()
		// if (position > 0) {
			$('html, body').stop().animate({
				scrollTop: 5
			}, 1000, 'easeInOutExpo')
		// }
		if(this.$route.query.type == 'promo') {
			this.isProduct= false
			this.isPromo= true
			this.isCategory= false
			this.isUMKM= false
		}
		else if(this.$route.query.type == 'category'){
			this.isProduct= false
			this.isPromo= false
			this.isCategory= true
			this.isUMKM= false
		}
		else if(this.$route.query.type == 'umkm'){
			this.isProduct= false
			this.isPromo= false
			this.isCategory= false
			this.isUMKM= true
		}
		else {
			this.isProduct= true
			this.isPromo= false
			this.isCategory= false
			this.isUMKM= false
		}
		
		this.$session.remove('product_keranjang')
		
		// JALANKAN PERINTAH DI BAWAH KETIKA REFRESH HALAMAN
		window.addEventListener('beforeunload', this.createSessions_onRouteLeave)
		
		window.ProductList = this
	},
	beforeRouteLeave (to, from , next) {
		// JALANKAN PERINTAH DI BAWAH SEBELUM PINDAH HALAMAN
		this.createSessions_onRouteLeave()
		next()
	},
}
</script>